/* Sidebar container */
.back-sidebar {
  width: 180px;
  background-color: #2d3a3b;
  color: white;
  padding: 10px;
  box-sizing: border-box;
  min-height: 100vh; /* Ensures the sidebar is at least as tall as the viewport */
  position: sticky;
  top: 0;
}

/* Sidebar content */
.back-sidebar-content {
  display: flex;
  flex-direction: column;
}

/* Section in Sidebar */
.back-section {
  margin-bottom: 15px;
}

/* Button to toggle sections */
.toggle-btn {
  width: 100%;
  padding: 10px;
  background-color: #444;
  color: white;
  border: 2px solid transparent;
  text-align: left;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s;
}

.toggle-btn:hover {
  background-color: #555;
}

/* Border color change when section is open */
.toggle-btn.active {
  border-color: #ff9900;
  background-color: #333;
}

/* Styling for active section links */
.back-section.active .toggle-btn {
  background-color: #333;
  color: #ff9900;
}

/* Links inside sections */
.back-section ul {
  padding-left: 20px;
  margin-top: 10px;
}

.back-section li {
  margin-bottom: 10px;
}

.back-section a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.back-section a:hover {
  color: #ff9900;
}
