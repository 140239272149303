/* Main container for the scholarship page */
.main-scholarship {
  display: flex;
  flex-direction: column;
  background-color: #dfebe6;
  /* justify-content: center; */
  /* gap: 20px; Increased gap for better separation */
  /* padding: 20px; Increased padding for more space */
  /* background-color: #f0f4f8; Soft background color */
}

/* Styles for the side panels */
.ss-side-panel1, .ss-side-panel2 {
  flex: 1;

  /* max-width: 200px; 
  min-width: 200px; */
  border-radius: 10px; /* Slightly more rounded corners */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  */
  /* background-color: #ffffff; Clean background color */
}

/* Container for the main content including side panes and the body */
.container-scholarship {
  /* flex: 4; */
  display: flex;
  margin: 10px; 

  
}

/* Main body content styles */
.body-scholarship {
  /* flex: 1; */
  border-radius: 10px;
  flex: 4;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  /* background-color: #ffffff;  */
  /* White background for readability */
  /* background-color: yellow; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

/* Scholarships list styles */
.scholarships-list {
  padding: 20px; /* Increased padding */
  /* flex: 1; */
  min-width: 0;
  border-radius: 10px;
  background-color: #ffffff; /* White background for list */
}

/* Heading style for scholarships */
.body-scholarship h1 {
  margin-bottom: 20px;
  font-size: 28px; /* Larger font size for headings */
  color: #333333; /* Dark color for better contrast */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font */
}

/* Scholarship card styles */
.scholarship-card {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #ffffff; /* White background for cards */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

/* Hover effect for scholarship card */
.scholarship-card:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px); /* Slight lift effect on hover */
}

/* Scholarship title style */
.scholarship-card h3 {
  margin: 0;
  font-size: 22px; /* Larger font size for titles */
  color: #0056b3; /* Darker blue for emphasis */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Consistent font */
}

/* Scholarship deadline style */
.scholarship-card p {
  margin: 10px 0 0;
  color: #666666; /* Darker gray for better readability */
  font-size: 16px; /* Slightly larger font size for readability */
}

/* Link style for viewing details */
.scholarship-card a {
  display: inline-block;
  margin-top: 15px;
  color: #0056b3;
  text-decoration: none;
  font-weight: bold; /* Bold link for emphasis */
}

/* Link hover effect */
.scholarship-card a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container-scholarship {
    /* flex-direction: column; */
    /* display: flex; */
    flex: 1;
  }
  .ss-side-panel1, .ss-side-panel2 {
    display: none; /* Hide side panels on smaller screens */
}  .body-scholarship {
    /* margin: 0 auto; */
    flex: 4;
    margin: 0;
  
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container-scholarship {
    /* flex-direction: column; */
    /* display: flex; */
    flex: 1;
  }
   .body-scholarship {
    /* margin: 0 auto; */
    flex: 4;
    margin: 0;
  
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .container-scholarship {
    /* flex-direction: column; */
    /* display: flex; */
    flex: 1;
  }
 .body-scholarship {
    /* margin: 0 auto; */
    flex: 4;
    margin: 0;
  
  }
}