.notification-container {
    display: flex;
    gap: 20px; /* Specify units for the gap */
}

.notification-page {
    padding: 20px;
    background-color: #ffffff; /* White background for the content area */
    border-radius: 8px; /* Rounded corners for a card-like appearance */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin: 20px; /* Margin to avoid content touching edges */
    overflow: auto; /* Handles overflow if content is too large */
    flex: 1; /* Allow it to take available space */
}

.notification-group {
    display: flex;
    align-items: center; /* Vertically center items */
    margin-bottom: 12px; /* Space between groups */
}

.form-label {
    margin-right: 10px; /* Space between label and input */
    font-weight: bold; /* Optional: Make the label bold */
    width: 150px; /* Fixed width for consistent alignment */
}

.job-selection,
.input-field {
    flex: 1; /* Allows input fields to grow and fill the space */
}

.job-selection select,
.input-field input,
.input-field textarea {
    width: 100%; /* Full width for inputs */
    padding: 8px 12px; /* Padding for comfort */
    border: 1px solid #0078d7; /* Border color */
    border-radius: 4px; /* Rounded corners */
    background-color: #f0f8ff; /* Light background color */
    font-size: 14px; /* Font size */
    transition: border-color 0.3s ease; /* Smooth transition for focus effects */
    resize: none; /* Prevent resizing on textareas */
}

/* Textarea specific styles for auto height */
.input-field textarea {
    min-height: 40px; /* Minimum height for textareas */
    overflow: hidden; /* Hide overflow to ensure it expands correctly */
}

/* Focus styles for inputs and textareas */
.job-selection select:focus,
.input-field input:focus,
.input-field textarea:focus {
    border-color: #005bb5; /* Darker blue on focus */
    outline: none; /* Remove default outline */
}

/* Button styling */
button {
    padding: 10px 15px; /* Button padding */
    background-color: #0078d7; /* Button background */
    color: #ffffff; /* Button text color */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover effects */
    font-size: 16px; /* Slightly larger font for buttons */
}

/* Button hover effect */
button:hover {
    background-color: #005bb5; /* Darker blue on hover */
}

/* Feedback message styling */
.feedback-message {
    margin-top: 20px; /* Space above the feedback message */
    padding: 10px;
    border: 1px solid #0078d7; /* Border color matching the theme */
    background-color: #e6f7ff; /* Light blue background for feedback */
    color: #005bb5; /* Text color */
    border-radius: 4px; /* Rounded corners */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .notification-container {
        flex-direction: column; /* Stack elements vertically on small screens */
    }

    .form-label {
        width: auto; /* Allow labels to take necessary space */
    }
}
