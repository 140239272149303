.feedback-container {
    width: 100%;
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.feedback-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    color: #333;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #28a745;
    outline: none;
}

.form-group textarea {
    resize: vertical;
    height: 100px;
}

.submit-button {
    width: 100%;
    padding: 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #218838;
}

.status-message {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    color: #28a745;
}

/* New Styles for Feedback Section */
.submitted-feedback {
    margin-top: 30px;
}

.feedback-item {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.feedback-item:hover {
    transform: scale(1.02);
}

.feedback-item p {
    margin: 10px 0;
    font-size: 16px;
    color: #444;
}

.feedback-item strong {
    color: #28a745;
    font-weight: bold;
}

.submitted-feedback p {
    text-align: center;
    font-size: 16px;
    color: #777;
}

/* Media Queries */
@media (max-width: 768px) {
    .feedback-container {
        padding: 15px;
        margin: 30px auto;
    }

    h2 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .form-group label {
        font-size: 13px;
    }

    .form-group input,
    .form-group textarea {
        font-size: 15px;
        padding: 8px;
    }

    .submit-button {
        padding: 10px;
        font-size: 16px;
    }

    .status-message {
        font-size: 15px;
    }

    .feedback-item {
        padding: 12px;
        margin-bottom: 12px;
    }

    .feedback-item p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .feedback-container {
        padding: 10px;
        margin: 20px auto;
    }

    h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .form-group label {
        font-size: 12px;
    }

    .form-group input,
    .form-group textarea {
        font-size: 14px;
        padding: 7px;
    }

    .submit-button {
        padding: 8px;
        font-size: 15px;
    }

    .status-message {
        font-size: 14px;
    }

    .feedback-item {
        padding: 10px;
    }

    .feedback-item p {
        font-size: 13px;
    }
}
