/* General Page Layout */
.jobs-list-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Jobs Page Container */
.back-jobs-container {
  display: flex;
  background-color: #f4f4f4;
  flex: 1;
  overflow: hidden;
  /* flex-direction: column; Stack content vertically on smaller screens */
 
}

/* Content Area */
.jobs-content {
  flex: 1;
  margin: 10px; /* Reduce margin for small screens */
  padding: 15px; /* Adjust padding for better fit */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Table Container */
.table-container {
  padding: 10px;
  /* overflow-x: auto; Enable horizontal scrolling for tables */
}

/* Header Styling */
.jobs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
}

.jobs-header h1 {
  margin: 0;
  color: #333;
}

/* Add Job Button Styling */
.add-job-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.add-job-btn:hover {
  background-color: #45a049;
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Table Styling */
.job-list-table {
  width: 100%;
  border-collapse: collapse;
  /* table-layout: auto; Allow flexible column widths */
}

.job-list-table th,
.job-list-table td {
  border: 1px solid #ddd;
  padding: 10px;
 
  white-space: normal;
  word-wrap: break-word;

  font-size: 14px;
  /* text-align: center; /* Centers text horizontally */
  /* vertical-align: middle; Centers text vertically */ 

}

.job-list-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Links for Edit and Delete */
.edit-link,
.delete-link {
  color: #4CAF50;
  text-decoration: none;
  font-weight: bold;
}

.edit-link:hover,
.delete-link:hover {
  text-decoration: underline;
}

/* No Jobs Available Message */
.no-jobs-message {
  text-align: center;
  font-size: 16px;
  color: #555;
  font-style: italic;
}

/* Responsive Styling */
@media (max-width: 768px) {
  /* General Page Layout */
.jobs-list-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Jobs Page Container */
.back-jobs-container {
  display: flex;
  background-color: #f4f4f4;
  flex: 1;
  /* overflow: hidden;
  overflow-x: auto; */
  /* flex-direction: column; Stack content vertically on smaller screens */
 
}

/* Content Area */
.jobs-content {
  flex: 1;
  margin: 10px; /* Reduce margin for small screens */
  padding: 15px; /* Adjust padding for better fit */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  overflow-x: auto;
}

 /* Table Container */
.table-container {
  padding: 10px;
  /* overflow-x: auto; Enable horizontal scrolling for tables */
  /* overflow-x: auto; */
}

  
/* Table Styling */
.job-list-table {
  width: 100%;
  border-collapse: collapse;
  padding: 10px 20px;
  /* table-layout: auto; Allow flexible column widths */
  overflow-x: auto;
}

  .jobs-header {
    display: flex;
    font-size: 18px; /* Reduce font size */
    
  }

  .jobs-header h1 {
    font-size: 18px; /* Reduce font size */
  }

  .add-job-btn {
    padding: 6px 12px;
    font-size: 12px; /* Smaller button font */
  }

  .job-list-table th,
  .job-list-table td {
    padding: 8px;
    font-size: 14px;
    text-align: center; /* Centers text horizontally */
    vertical-align: middle; /* Centers text vertically */
  }

  .no-jobs-message {
    font-size: 14px;
  }
}

/* Action Buttons (Edit & Delete) */
.actions a {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease, box-shadow 0.3s ease-in-out;
}

/* Edit Button */
.actions .edit-link {
  background-color: #007bff;
  color: white;
  background-image: linear-gradient(45deg, #007bff, #0056b3);
}

.actions .edit-link:hover {
  background-color: #0056b3;
  background-image: linear-gradient(45deg, #0056b3, #004085);
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.actions .edit-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5);
}

/* Delete Button */
.actions .delete-link {
  background-color: #e74c3c;
  color: white;
  background-image: linear-gradient(45deg, #e74c3c, #c0392b);
}

.actions .delete-link:hover {
  background-color: #c0392b;
  background-image: linear-gradient(45deg, #c0392b, #a93226);
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.actions .delete-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(231, 76, 60, 0.5);
}

/* Active State for Edit and Delete Buttons */
.actions a:active {
  transform: scale(0.98);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Toggle Details Button */
.details-toggle-btn {
  background-color: #f2f2f2;
  color: #333;
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease;
}

.details-toggle-btn:hover {
  background-color: #ddd;
  transform: scale(1.05);
}

/* Expanded Details Styling */
.details-row {
  background-color: #f9f9f9;
  border-top: 2px solid #ddd;
}

.jobs-details-table {
  width: 100%;
  border-collapse: collapse;
}

.jobs-details-table th,
.jobs-details-table td {
  padding: 10px;
  text-align: left;
}

.jobs-details-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.jobs-details-table td {
  color: #555;
}
