/* Main container for scholarship details */
.scholarship-details {
  display: flex;
  flex-direction: column;
}

/* Container for the content and side panels */
.datails-container {
  margin: 10px;
  display: flex;

}

/* Side panel styling */
/* .side-panel1, .side-panel2 {
  max-width: 200px;
  border-radius: 8px;
} */

/* Main container for the details */
.details-main-container {
  flex: 4;
  border-radius: 10px;
  background-color: #ffffff; /* White background */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 10px;

}

/* Content inside the main details container */
.scholarship-content {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add spacing between elements */
}

/* Info section for scholarships */
.scholarships-details-info {
  background-color: #f8f9fa; /* Light background for info section */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); /* Light shadow */
}

/* Title styling */
.scholarship-title {
  font-size: 2rem;
  color: #333333; /* Dark text for readability */
  margin-bottom: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-bottom: 2px solid #eaeaea; /* Underline for separation */
  padding-bottom: 10px;
}

/* General text styling */
.scholarships-details-info p {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555555;
  line-height: 1.6;
}

/* Link styling for website and apply URL */
.scholarships-details-info a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.scholarships-details-info a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Media Queries */

/* For devices with a max-width of 768px */
@media (max-width: 768px) {
  .datails-container {
    flex-direction: column;
  }

  .details-main-container {
    padding: 10px;
    margin: 10px;
  }

  .scholarship-title {
    font-size: 1.8rem;
  }

  .scholarships-details-info {
    padding: 10px;
  }

  .ss-side-panel1, .ss-side-panel2 {
    display: none; /* Hide side panels */
  }
}

/* For devices with a width between 768px and 920px */
@media (min-width: 768px) and (max-width: 920px) {
  .details-main-container {
    flex: 4;
    padding: 15px;
    margin: 15px;
  }

  .scholarship-title {
    font-size: 1.9rem;
  }

  .scholarships-details-info {
    padding: 15px;
  }

  
}

/* For devices with a width between 920px and 1200px */
@media (min-width: 920px) and (max-width: 1200px) {
  .details-main-container {
    flex: 4;
    padding: 18px;
    margin: 18px;
  }

  .scholarship-title {
    font-size: 2rem;
  }

  .scholarships-details-info {
    padding: 18px;
  }

 
}

/* For devices with a minimum width of 1200px */
@media (min-width: 1200px) {
  .details-main-container {
    padding: 20px;
    margin: 20px;
  }

  .scholarship-title {
    font-size: 2rem;
  }

  .scholarships-details-info {
    padding: 20px;
  }

  .side-panel1, .side-panel2 {
    max-width: 300px;
  }
}
