.jobs-edit-item-page {
  width: 100%;
  background-color: rebeccapurple;
}

/* Sidebar on the left */
.jobs-sidebar-container {

    display: flex;
    background-color: #f4f4f4;
    flex: 1;

}

/* Content area on the right */
.jobs-item-content {
  flex: 1;
  margin: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
