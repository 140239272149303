/* Main header container */
.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 20px; */
  background-color: #263238; /* Darker header background */
  color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  box-sizing: border-box;
}

/* Header container */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Dashboard title */
.dashboard-title {
  font-size: 26px;
  font-weight: bold;
  margin: 0;
  color: #e0f7fa; /* Softer accent color */
}

/* User profile section */
.user-profile {
  display: flex;
  align-items: center;
}

.user-name {
  margin-right: 15px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  padding: 10px;
}

/* Profile container */
.profile-container {
  position: relative;
}

.profile-pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  object-fit: cover;
  padding: 10px;

}

.profile-pic:hover {
  transform: scale(1.1);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.4);
}

/* Dropdown menu */
.profile-dropdown {
  position: absolute;
  top: 55px;
  right: 0;
  background-color: #37474f; /* Consistent dark background */
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 180px;
  z-index: 9999;
}

.profile-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 8px 0;
}

.profile-dropdown li {
  padding: 10px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.profile-dropdown li:hover {
  background-color: #455a64;
  color: #e0f7fa;
}

/* Responsive design for smaller screens */
@media screen and (max-width: 768px) {
  .admin-header {
    flex-direction: column;
    text-align: center;
    padding: 15px 20px;
  }

  .header-container {
    flex-direction: column;
    align-items: center;
  }

  .dashboard-title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .user-profile {
    margin-top: 10px;
    justify-content: center;
  }

  .profile-pic {
    width: 40px;
    height: 40px;
    padding: 10px;

  }
}

/* Larger screen adjustments */
@media screen and (min-width: 769px) {
  .profile-pic {
    width: 50px;
    height: 50px;
  }
}
