/* General container styling */
.user-login-page {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    margin: 0;
  }
  
  .login-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h2 {
    color: #333;
    font-size: 24px;
    margin: 0;
  }
  
  /* Login form styling */
  .user-login-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  /* Form group styling */
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  input.form-control {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input.form-control:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
  
  /* Button styling */
  .btn.btn-primary {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn.btn-primary:hover {
    background-color: #0056b3;
  }
  
  /* Error message styling */
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
    text-align: center;
  }
  