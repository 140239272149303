/* Sliding in from the left when the button appears */
.see-more,
.see-less {
  display: inline-block;
  padding: 4px 10px;
  color: #0078d7;
  background-color: white;
  border: 2px solid #0078d7;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  animation: slideIn 0.5s ease forwards; /* Slide in from left */
  transition: transform 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}
.see-more-less{
  gap: 10px; /* Space between the info sections */
  margin: 10px 0; /* Adds vertical spacing (10px) to the top and bottom */
}

.parse-see-more-less {
  text-align: justify;
  line-height: 1.5;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100px); /* Start 100px to the left */
    opacity: 0; /* Start invisible */
  }
  100% {
    transform: translateX(0); /* End in original position */
    opacity: 1; /* End fully visible */
  }
}

/* Sliding to the right on hover */
.see-more:hover,
.see-less:hover {
  /* background-color: #0078d7; */
  color: white;
  transform: translateX(10px); /* Slide 10px to the right */
}

.updated-deadline {
  display: flex;
  gap: 10px; /* Space between the info sections */
  margin: 10px 0; /* Adds vertical spacing (10px) to the top and bottom */
}


/* Styling for Updated section */
.updated-section {
    padding: 2px 2px;
    border: 2px solid #0078d7; /* Blue border matching your theme */
    border-radius: 8px; /* Slightly more rounded corners for a modern look */
    background-color: #e6f7ff; /* Light blue background for updated */
    font-weight: bold;
    color: #005bb5; /* Darker blue for updated text */
    transition: transform 0.3s ease, border-color 0.3s ease, background-color 0.3s ease; /* Smooth transitions for hover effects */
    cursor: default; /* Indicates that it's not clickable */
}

/* Optional: Hover effect for interactivity */
.updated-section:hover {
    border-color: #005bb5; /* Darker blue border on hover */
    background-color: #ccf2ff; /* Slightly darker background on hover */
    transform: translateY(-5px); /* Slight lift effect */
}

/* Styling for Deadline section */
.deadline-section {
    padding: 2px 2px;
    border: 2px solid #0078d7; /* Blue border matching your theme */
    border-radius: 8px; /* Slightly more rounded corners for a modern look */
    background-color: #e6f7ff; /* Light blue background for updated */
    font-weight: bold;
    color: #005bb5; /* Darker blue for updated text */
    transition: transform 0.3s ease, border-color 0.3s ease, background-color 0.3s ease; /* Smooth transitions for hover effects */
    cursor: default; /* Indicates that it's not clickable */
}




/* Optional: Hover effect for interactivity */
.deadline-section:hover {
    border-color: #c9302c; /* Darker red border on hover */
    background-color: #f5c6cb; /* Slightly darker background on hover */
    transform: translateY(-5px); /* Slight lift effect */
}


/* Initial setup for status-section */
.status-section {
  padding: 10px;
  font-weight: bold;
  color: #fff;
  border-radius: 8px;
  background: linear-gradient(45deg, #4caf50, #fad0c4, #4caf50); /* Existing background gradient */
  background-size: 400% 400%; /* Ensures the gradient moves */
  animation: gradientAnimation 5s ease infinite; /* Apply animation to background */
}

/* Styling for Active Status */
.active-status {
    padding: 2px 2px;
    border: 3px solid #28a745; /* Green border for active status */
    border-radius: 8px; /* Slightly more rounded corners */
    font-weight: bold;
    color: #155724; /* Darker green for active status text */
    transition: transform 0.3s ease, border-color 0.3s ease, background-color 0.3s ease; /* Smooth transitions for hover effects */
    cursor: default; /* Indicates that it's not clickable */

    /* Apply linear gradient to border using border-image */
   background-color: #d4edda; /* Light green background for active status */
}

/* Optional: Hover effect for interactivity */
.active-status:hover {
    border-color: #218838; /* Darker green border on hover */
    background-color: #c3e6cb; /* Slightly darker background on hover */
    transform: translateY(-5px); /* Slight lift effect */
}

/* Styling for Expired Status */
.expired-status {
    padding: 2px 2px;
    border: 3px solid #d9534f; /* Red border for expired status */
    border-radius: 8px; /* Slightly more rounded corners */
  
    font-weight: bold;
    color: #721c24; /* Darker red for expired status text */
    transition: transform 0.3s ease, border-color 0.3s ease, background-color 0.3s ease; /* Smooth transitions for hover effects */
    cursor: default; /* Indicates that it's not clickable */
     background-color: #f8d7da; /* Light red background for expired status */
}

/* Optional: Hover effect for interactivity */
.expired-status:hover {
    border-color: #c9302c; /* Darker red border on hover */
    background-color: #f5c6cb; /* Slightly darker background on hover */
    transform: translateY(-5px); /* Slight lift effect */
}


/* Keyframes for the linear gradient background animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* see-more-details container */
.see-more-details {
  margin: 15px 0; /* Add some space above and below */
  text-align: center; /* Center-align the content */
}

/* see-more-details link */
/* see-more-details container */
.see-more-details {
  margin: 15px 0;
  text-align: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.see-more-details:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

/* see-more-details link */
.see-more-details a {
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.3s ease;
  display: inline-block;
}

/* Hover effect */
.see-more-details a:hover {
  color: #0056b3;
  text-decoration: none;
  background: linear-gradient(90deg, rgba(0, 123, 255, 0.1), rgba(0, 123, 255, 0.05));
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

/* Active state */
.see-more-details a:active {
  color: #004085;
  transform: scale(0.98);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Focus state */
.see-more-details a:focus {
  outline: 2px solid #007bff;
  outline-offset: 4px;
  background-color: rgba(0, 123, 255, 0.1);
  border-radius: 4px;
}

/* Icon styling */
.see-more-details a i {
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.see-more-details a:hover i {
  transform: translateX(4px);
}

/* Optional: Bounce animation for icon */
@keyframes bounce {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(6px);
  }
}

.see-more-details a:hover i {
  animation: bounce 0.6s ease;
}

/* Small screens responsiveness */
@media (max-width: 768px) {
  .see-more-details {
    margin: 10px 0;
  }

  .see-more-details a {
    font-size: 14px;
    padding: 6px 10px;
  }
}
.updated-section span {
  display: inline-block; /* Ensure the text behaves as a single unit */
  padding: 5px 5px; /* Add some padding for better spacing */
  border-radius: 5px; /* Add slight rounding to the corners */
  font-weight: 500; /* Make the text slightly bold for emphasis */
}