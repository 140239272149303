/* General Page Layout */
/* .jobs-delete-item-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f8f9fa;
    font-family: Arial, sans-serif;
  }
   */
  .jobs-delete-container {
    display: flex;
    flex: 1;
  }
  
  .jobs-item-content {
    flex: 1;
    background-color: #ffffff;
    padding: 30px;
    margin-left: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Header */
  .jobs-item-content h2 {
    font-size: 24px;
    color: #333333;
    margin-bottom: 20px;
  }
  
  /* Session Messages */
  .session-message {
    margin-bottom: 20px;
  }
  
  .error-message {
    color: #dc3545;
    font-size: 16px;
    font-weight: bold;
    background-color: #f8d7da;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .success-message {
    color: #28a745;
    font-size: 16px;
    font-weight: bold;
    background-color: #d4edda;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  /* Delete Button */
  .delete-btn {
    background-color: #dc3545;
    color: #ffffff;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .delete-btn:hover {
    background-color: #c82333;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .jobs-delete-container {
      flex-direction: column;
    }
  
    .jobs-item-content {
      margin-left: 0;
      margin-top: 20px;
    }
  
    .delete-btn {
      width: 100%;
      padding: 15px;
      font-size: 18px;
    }
  }
  