/* General Page Styling */
.companies-list-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container-page {
  display: flex;
  background-color: #f8f9fa; /* Lighter background for a fresh look */
  flex: 1;
  /* overflow: hidden; */
  overscroll-behavior: none;
}

/* List Section */
.list-of-companies {
  flex: 1;
  margin: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* overflow-x: auto; */
}

/* add comp btn */
/* Header Styling */
.company-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
}

.company-header h1 {
  margin: 0;
  color: #333;
  font-size: 16px;
}

/* Add Job Button Styling */
.add-company-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.add-company-btn:hover {
  background-color: #45a049;
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Button Styling
.add-company-btn {
  background-color: #007bff; /* Primary blue color */
  /* color: white;
  border: none;
  border-radius: 6px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.add-company-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
} */

/* .add-company-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5);
} */ 

.table-container{
  width: 100%;
}

/* Table Styling */
.companies-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd; /* Border for the table */
  
}

.companies-table th,
.companies-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;

}

.companies-table th {
  background-color: #007bff;
 
  color: white;
  font-weight: bold;
}

.companies-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.companies-table tr:hover {
  background-color: #eaeaea;
}

/* Details Styling */
.details-toggle-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
}

.details-toggle-btn:hover {
  background-color: #218838;
}

.details-row {
  background-color: #f4f4f4;
  padding: 12px;
  border-radius: 8px;
}

.company-details p {
  margin: 8px 0;
}

/* Error Message */
.error-message {
  color: #e74c3c;
  font-size: 14px;
  margin-top: 10px;
  font-weight: bold;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .back-sidebar {
    width: 100%;
  }
  ul li a {
    font-size: 14px;
    padding: 4px;
  }
  .list-of-companies {
    padding: 10px;
  }

  .table-container {
    padding: 10px;
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  .companies-table {
    width: 100%;
    font-size: 14px;
    table-layout: auto; /* Columns adjust dynamically */
 
  }
/* companies-table */

 .companies-table th, .companies-table td {
    padding: 8px;
  }

  .add-company-btn {
    padding: 10px 20px;
  }
}

/* Company Details Table Styling */
.company-details-table {
  width: 100%;
  border-collapse: collapse;
}

.company-details-table th {
  text-align: left;
  padding: 10px;
  background-color: #f1f1f1;
  font-weight: bold;
}

.company-details-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.company-details-table td a {
  color: #007bff;
  text-decoration: none;
}

.company-details-table td a:hover {
  text-decoration: underline;
  color: #0056b3;
}

/* Edit and Delete Button Styling */
.actions a {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s ease-in-out;
}

/* Edit Button */
.actions .edit-link {
  background-color: #007bff;
  background-image: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
}

.actions .edit-link:hover {
  background-color: #0056b3;
  background-image: linear-gradient(45deg, #0056b3, #004085);
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.actions .edit-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5);
}

/* Delete Button */
.actions .delete-link {
  background-color: #e74c3c;
  background-image: linear-gradient(45deg, #e74c3c, #c0392b);
  color: white;
}

.actions .delete-link:hover {
  background-color: #c0392b;
  background-image: linear-gradient(45deg, #c0392b, #a93226);
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.actions .delete-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(231, 76, 60, 0.5);
}

/* Active state for Edit and Delete buttons */
.actions a:active {
  transform: scale(0.98);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
