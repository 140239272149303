/* Job detail card styling */
.jobs-detail-container .mycard {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Job title and headings */
.jobs-info h1 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
}

/* General paragraph styling */
.jobs-info p, .jobs-info div {
  margin: 10px 0;
  line-height: 1.5;
  font-size: 16px;
}

/* Job details list styling */
.job-details-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.job-details-list li {
  margin: 5px 0;
}

/* Job apply link styling */
.job-apply-link {
  color: #3498db;
  text-decoration: none;
}

.job-apply-link:hover {
  text-decoration: underline;
}

/* Custom horizontal rule */
.border-custom {
  border: 0;
  height: 1px;
  background: linear-gradient(to right, #ddd, #bbb, #ddd);
  margin: 20px 0;
}

/* Pagination styling */
.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.pagination a {
  color: #3498db;
  text-decoration: none;
  margin: 0 5px;
  font-size: 16px;
}

.pagination a:hover {
  text-decoration: underline;
}

/* Additional information styling */
.additional-info {
  padding: 15px;
  border-radius: 8px;
}
.justify-text {
  text-align: justify;
}