/* Loading.css */

.loading-content {
    display: flex;
    justify-content: center;
    height: 100vh;
    color: #333;
}

.loading-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spinner {
    display: flex; /* Changed to flex for better control */
    justify-content: center;
    align-items: center; /* Centers the text inside the spinner */
    width: 80px;
    height: 80px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
    position: relative;
}

.spinner p {
    position: absolute;
    margin: 0;
    padding: 0;
    font-size: 10px;
    color: #333;
    text-align: center;
}

/* Spinner Animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
