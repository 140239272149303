.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  button {
    background-color: transparent;
    border: none;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:disabled {
    color: #d3d3d3;
    cursor: not-allowed;
  }
  
  button.active {
    background-color: red;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
  }
  
  button.prev,
  button.next {
    font-size: 16px;
  }
  