/* NotFound.css */
.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    /* padding: 2rem; */
}
.not-found-body{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found-body h1 {
    font-size: 6rem;
    margin-bottom: 1rem;
}

.not-found-body h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.not-found-body p {
    margin-bottom: 2rem;
}

.not-found-body a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}
