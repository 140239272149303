/* hr separator */
.separator{
    /* border: none; */
    margin: 0;
    padding: 0;
    border: none; /* Remove default border */
    border-top: 1px solid white; /* Style only the top border */
    background: linear-gradient(45deg, #427c91, #2a5298, #427c91, #4a69bd, #b9c8cc);
}
/* Navbar Container */
.container-fluid {
  display: flex;
  align-items: center;
  /* #427c91 */
  /* background: linear-gradient(45deg, #1e3c72, #2a5298, #3b5998, #4a69bd, #2a5298); */
 
   background: linear-gradient(45deg, #427c91, #2a5298, #427c91, #4a69bd, #b9c8cc);

  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Left and Right Nav (Placeholders) */
.left-nav,
.right-nav {
  flex: 1;
}

/* Center Nav (Main Navigation) */
.center-nav {
  flex: 4;
}

nav {
  display: flex;
  padding: 10px;
  gap: 20px;
  justify-content: flex-start; /* Align items to the left */
}

/* Logo Wrapper */
.logowrapper {
  display: flex;
  align-items: center; /* Center align items vertically */
}

.logowrapper img {
  width: 50px;
  height: 40px;
  border-radius: 50%;
}

.logowrapper h4 a {
  text-decoration: none;
  color: white;
  font-size: 24px;
  font-weight: bold;
  white-space: nowrap;
  font-weight: 700; /* Bold text */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Navigation Links */
.Nav-Links {
  display: flex;
  align-items: center; /* Center align items vertically */
  margin-left: auto; /* Push Nav-Links to the right */
}

.navigations {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigations li {
  color: #000000;
}

.navigations li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 10px;
  transition: all 0.3s ease;
  display: block;
  font-weight: 700; /* Bold text */
}

.navigations li a:hover {
  background-color: #ffd700;
  color: #000000;
  border-radius: 5px;
}

.navigations li.active a {
  background-color: #ff6600;
  color: white;
  border-radius: 5px;
}

/* Mobile Menu Toggle */
.mobile-menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container-fluid {
    align-items: flex-start;
    overflow-x: hidden; /* Prevent horizontal overflow */
    width: 100%; /* Use 100% instead of 100vw to avoid overflow */
  }

  .left-nav,
  .right-nav {
    display: none;
  }

  .center-nav {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Ensure center-nav takes full width */
    padding: 0 10px; /* Add padding to prevent content from touching edges */
    box-sizing: border-box; /* Include padding in width calculation */
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
    width: 100%; /* Ensure nav takes full width */
    padding: 10px; /* Add padding for better spacing */
    box-sizing: border-box; /* Include padding in width calculation */
  }

  .Nav-Links {
    flex: 1;
    display: none;
    width: 100%; /* Ensure Nav-Links takes full width */
    box-sizing: border-box; /* Include padding in width calculation */
  }

  .Nav-Links.active {
    display: flex; /* Show Nav-Links when active class is applied */
    flex-direction: column;
    background-color: #2a5298;
    padding: 10px;
    border-radius: 5px;
    width: 100%; /* Ensure Nav-Links takes full width */
  }

  .navigations {
    flex-direction: column;
    width: 100%; /* Ensure navigations take full width */
    padding: 0;
    margin: 0;
    box-sizing: border-box; /* Include padding in width calculation */
  }

  .navigations li {
    width: 100%; /* Ensure list items take full width */
    text-align: center;
  }

  .navigations li a {
    padding: 10px;
    display: block;
    box-sizing: border-box; /* Include padding in width calculation */
    font-weight: 700; /* Bold text */
    font-size: 18px; /* Increase font size for mobile */
  }

  .logowrapper h4 a {
    font-size: 20px; /* Adjust logo font size for mobile */
  }

  .mobile-menu-toggle {
    display: block; /* Show toggle button on mobile */
    position: absolute;
    right: 1rem;
    color: white; /* Ensure the toggle icon is visible */
    top: auto;
    background: none; /* Remove default button background */
    border: none; /* Remove default button border */
    cursor: pointer; /* Add pointer cursor for better UX */
    font-size: 28px; /* Increase toggle button size for better visibility */
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .container-fluid {
    display: flex;
  }

  .navigations {
    gap: 5px;
  }

  .navigations li a {
    padding: 5px;
  }
}

@media (min-width: 991px) {
  .container-fluid {
    display: flex;
  }

  .navigations {
    color: wheat;
  }

  .navigations li a {
    padding: 10px;
  }
}