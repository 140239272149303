/* Container for the layout */
.jobs-list-container {
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    /* gap: 10px;  */
    /* Enhanced spacing for clarity */
    /* padding: 4px; */
    background-color: #dfebe6;
    /* background-color: red; */
    /* background: linear-gradient(45deg, #427c91, #2a5298, #427c91, #4a69bd, #b9c8cc); */
  

    position: sticky;
    top: 0;
    z-index: 1000;
}
.searches{
    background: linear-gradient(45deg, #427c91, #2a5298, #427c91, #4a69bd, #b9c8cc);
 
}

/* Styles for the side panels */
.jobs-side-panel1, .jobs-side-panel2{
    /* padding: 15px;
    border-radius: 8px; */
    flex: 1;
    /* background-color: #4a69bd; */
   
    
}


/* Styles for the main content area */
.jobs-main-container {
    /* flex: 1; */

    flex: 4;
    padding: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    /* background-color: #ffffff;  */
    /* White background for readability */
    /* background-color: yellow; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Card Styling */
.mycard {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
    background-color: #ffffff;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.mycard h2{
    align-items: flex-start;
}


.mycard:hover {
    transform: translateY(-5px); /* Subtle hover effect */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 10px;
}

/* Job Overview */
.overview-jobs {
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;   
}

.overview-jobs p {
    font-size: 16px;
    color: #333;
    /* line-height: 1.6; */
    margin: 5px 0;
}
.overview-jobs ul li {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    margin: 5px 0;
}

.overview-jobs p strong {
    color: #007bff;
    font-weight: 600;
}

/* Buttons */
.button-detail-apply {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.btn-details {
    display: inline-block;
    padding: 10px 20px;
    color: #0078d7;
    background-color: transparent;
    border: 2px solid #0078d7;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-details:hover {
    background-color: #0078d7;
    color: #ffffff;
}

/* Responsive Design */

/* For screens up to 768px */
@media (max-width: 768px) {
    .jobs-list-container {
        flex-direction: column;
        gap: 10px;
        flex: 1;
    }
    .side-panel1, .side-panel2 {
        display: none; /* Hide side panels on smaller screens */
    }
    .jobs-main-container {
        padding: 8px;
        margin: 0;
        padding: 15px;
        flex: 1;
    }
    .overview-jobs{
        padding: 4px;
        /* background-color: red; */
    }
}

/* For screens 769px to 991px */
@media (min-width: 768px) and (max-width: 991px) {
    .jobs-list-container {
        display: flex;
        /* gap: 10px; */
    }
    
    .jobs-main-container {
        /* flex: 1; */
        flex: 4;
        padding: 15px;
        /* flex-grow: 1; */
        /* background-color: yellow; */
    }
}

/* For screens 992px to 1200px */
@media (min-width: 991px) and (max-width: 1200px) {

    .jobs-list-container {
        display: flex;
        /* gap: 10px; */
    }

    .jobs-main-container {
        flex: 4;
        padding: 15px;
        /* flex-grow: 1; */
        /* background-color: red; */
    }
}

/* For screens above 1200px */
@media (min-width: 1200px) {

    .jobs-list-container {
        display: flex;
        /* gap: 10px; */
    }
  
    .jobs-main-container {
        flex: 4;
        padding: 15px;
    }
}
