/* Container for the layout */
.searchform-container{
    display: flex;
    justify-content: center;
    gap: 10px; /* Increased gap for better spacing */
    padding: 4px; /* Added padding around the container */
    /* background-color: #007bff; */
    /* background: linear-gradient(45deg, #1e3c72, #2a5298, #3b5998, #4a69bd, #2a5298); */

    /* background: linear-gradient(45deg, #427c91, #2a5298, #427c91, #4a69bd, #b9c8cc); */
 
}

/* Styles for the first sidebar */
.search-side-panel1 {
    /* background-color: aqua; */
    padding: 15px; /* Increased padding for better spacing */
    max-width: 200px;
    min-width: 200px; /* Adjusted minimum width for better balance */
    /* Adjusted minimum width for better balance */
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);  */
    border-radius: 8px; /* Optional rounded corners */
}

/* Styles for the main content area */
.search-main-form {
    /* background-color: blueviolet; */
    padding: 4px; /* Increased padding for better spacing */
    flex: 1; /* Ensures the main content takes up available space */
    min-width: 0; /* Allows the content to shrink properly */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); 
    border-radius: 8px; /* Optional rounded corners */
}

/* Styles for the second sidebar */
.search-side-panel2 {
    /* background-color: rgb(43, 226, 73); */
    padding: 15px; /* Increased padding for better spacing */
    max-width: 200px;
    min-width: 200px; /* Adjusted minimum width for better balance */
     /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);  */
    border-radius: 8px; /* Optional rounded corners */
}

/* Form container within search main form */
.searchform-info {
    width: 100%;
    /* padding: 10px; */
}

/* Styles for the form inside the search main form */
.searchform-info form {
    display: flex;
    align-items: center; /* Vertically center elements */
    gap: 10px; /* Space between form elements */
    flex-direction: row;
}

/* Styling for individual form elements */
.selectCategory select,
.inputSearch input,
.searchButton button {
    flex: 1; /* Allow elements to grow and shrink */
    padding: 15px;
    border: 1px solid #ccc;
    /* border: 1px solid #cccccc; */
    border-radius: 5px;
    font-size: 16px; /* Font size for readability */
    box-sizing: border-box; /* Include padding and border in total width/height */
    margin: 0;
}


/* Specific button styling */
.searchButton button {
    flex: 1;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.searchButton button:hover {
    background-color: #0056b3;
}
.inputSearch{
    width: 100%;
}
/* Responsive design adjustments for screens up to 768px */
@media (max-width: 768px) {
    .searchform-container {
        flex-direction: column;
        gap: 10px; /* Adjusted gap for vertical layout */
        /* background-color: black; */
    }

    .search-side-panel1, .search-side-panel2 {
        max-width: none; /* Remove max-width in mobile view */
        min-width: 100%; /* Full width for side panels */
        display: none;

    }

    .search-main-form {
       flex: 1; /* Ensures the main content takes up available space */
       padding: 10px; /* Adjusted padding for mobile view */
    }
}

/* Responsive design adjustments for screens between 769px and 991px */
@media (min-width: 768px) and (max-width: 991px) {
    .searchform-container {
        /* background-color: #007bff; */
        display: flex;
        gap: 10px; /* Adjusted gap for medium screens */
        /* background-color: yellow; */
    }

    .search-side-panel1, .search-side-panel2 {
        max-width: 100px; /* Fixed width for side panels */
        min-width: 100px; /* Adjusted minimum width */
    }

    .search-main-form {
        padding: 10px; /* Adjusted padding for medium screens */
        flex: 1; /* Ensures the main content takes up available space */
    }
}

/* Responsive design adjustments for screens between 992px and 1200px */
@media (min-width: 992px) and (max-width: 1200px) {
    .searchform-container{
        
        flex-direction: row; /* Ensures horizontal layout */
        gap: 10px; /* Adjusted gap for larger screens */
        /* background-color: blue; */
    }

    .search-side-panel1, .search-side-panel2 {
        max-width: 200px; /* Increased width for side panels */
        min-width: 150px; /* Adjusted minimum width */
    }

    .search-main-form {
        padding: 10px; /* Consistent padding for larger screens */
        flex: 1; /* Ensures the main content takes up available space */
    }
}
