/* ScholarshipCard.css */
.scholarship-card {
    border: 1px solid #ccc;
    /* margin: 10px; */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .scholarship-card:hover {
    background-color: #f9f9f9;
  }
  
  .scholarship-card h3 {
    margin: 0 0 10px 0;
    font-size: 1.5em;
  }
  
  .scholarship-card p {
    margin: 0 0 10px 0;
  }
  
  .scholarship-card a {
    color: #007bff;
    text-decoration: none;
  }
  
  .scholarship-card a:hover {
    text-decoration: underline;
  }
  /* Link style for viewing scholarship details */
.scholarship-link {
  display: inline-block;
  padding: 10px 20px;
  color: #ffffff;
  background-color: #0056b3; /* Blue background for emphasis */
  text-decoration: none;
  border-radius: 5px; /* Rounded corners for a modern look */
  font-weight: bold; /* Bold text for emphasis */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

/* Link hover effect */
.scholarship-link:hover {
  background-color: #003d80; /* Darker blue for hover effect */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

  