/* Error.css */

.error-container {
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically and horizontally center the content */
    height: 100vh; /* Full viewport height */
    background-color: #f8d7da; /* Soft red background */
    margin: 0;
}

.error-content {
    text-align: center; /* Center the content within the section */
    padding: 20px;
    max-width: 600px; /* Limit the width for better readability */
    background-color: #ffffff; /* White background for the error box */
    border-radius: 8px; /* Rounded corners */
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for better contrast */
}

.error-title {
    font-size: 32px; /* Larger font size for the title */
    color: #721c24; /* Dark red color for the error title */
    margin-bottom: 20px; /* Space between title and message */
    font-weight: bold; /* Make the title bold */
/*    background-color: black;*/

}

.error-body p {
    font-size: 18px; /* Increase font size for the message */
    color: #721c24; /* Dark red color for the message */
    margin-bottom: 30px; /* More space below the message */
    line-height: 1.5; /* Better line spacing for readability */
}

.error-retry-button {
    background-color: #f5c6cb; /* Light red background for the button */
    border: none;
    padding: 12px 25px; /* Larger padding for the button */
    font-size: 18px; /* Slightly larger text size */
    color: #721c24; /* Dark red color for the button text */
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow to give depth */
}

.error-retry-button:hover {
    background-color: #f1b0b7; /* Slightly darker red on hover */
}

/* Accessibility improvements */
.error-retry-button:focus {
    outline: 2px solid #721c24; /* Focus outline for better accessibility */
}
