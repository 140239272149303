/* General Styles */
.loginpage {
  display: flex;
  padding: 10px;
  margin: 0 1rem;
  gap: 10px; /* Add spacing between the sidebars */
  font-family: "Arial", sans-serif; /* Set a default font family */
}

/* Login Form Styling */
.loginform {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.Username,
.Email,
.Password,
select {
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 10px;
  font-family: inherit; /* Inherit font family */
  font-size: 16px; /* Consistent font size for inputs */
  border: 1px solid #ccc; /* Add border for consistency */
}

/* Subscribe Button Container */
.subscribe-btn {
  margin-bottom: 5px; /* Consistent margin */
}

/* Subscribe Button Styling */
.subscribe-btn button {
  background-color: #007bff; /* Blue background */
  color: #fff; /* White text */
  padding: 10px; /* Consistent padding with inputs */
  border: none; /* Removes border */
  border-radius: 10px; /* Consistent border radius */
  cursor: pointer; /* Changes cursor to pointer on hover */
  font-size: 16px; /* Consistent font size */
  font-family: inherit; /* Inherit font family */
  font-weight: 500; /* Medium font weight */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effect */
  width: 100%; /* Full width */
}

.subscribe-btn button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.subscribe-btn button:active {
  transform: scale(0.95); /* Slightly shrink on click */
}

/* Login Card Styling */
.myLoginCard {
  border-radius: 10px;
  margin-bottom: 20px; /* Increased margin for better spacing */
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 6px,
              rgba(0, 0, 0, 0.09) 0px -3px 5px; /* Minimized shadow */
  font-family: inherit; /* Inherit font family */
}

.myLoginCard h2 {
  font-size: 22px; /* Larger font size for headings */
  font-weight: 600; /* Semi-bold for headings */
  margin-bottom: 15px;
}

.myLoginCard p {
  font-size: 16px; /* Consistent font size for paragraphs */
  line-height: 1.5; /* Improved readability */
}

/* Profile Image Container */
.ProfileImage {
  display: block;
  margin: 0 auto 10px auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

/* Message Styles */
.form-message {
  padding: 15px 20px; /* Padding for spacing */
  margin-top: 15px; /* Space above the message */
  border-radius: 8px; /* Rounded corners */
  font-size: 16px; /* Font size */
  line-height: 1.5; /* Line height for text spacing */
  position: relative; /* For positioning the icon */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  font-family: inherit; /* Inherit font family */
}

.form-message.success {
  background-color: #d4edda; /* Light green background for success */
  color: #155724; /* Dark green text color */
  border: 1px solid #c3e6cb; /* Border color matching the background */
}

.form-message.success::before {
  content: "✅"; /* Success icon */
  margin-right: 10px; /* Space between icon and text */
  font-size: 20px; /* Icon size */
}

.form-message.error {
  background-color: #f8d7da; /* Light red background for error */
  color: #721c24; /* Dark red text color */
  border: 1px solid #f5c6cb; /* Border color matching the background */
}

.form-message.error::before {
  content: "⚠️"; /* Warning icon */
  margin-right: 10px; /* Space between icon and text */
  font-size: 20px; /* Icon size */
}

/* Login Advantages List */
.login-advantages {
  list-style: none;
  padding: 0;
}

.login-advantages li {
  margin-bottom: 10px;
  font-size: 16px; /* Consistent font size */
  line-height: 1.5; /* Improved readability */
}

/* Thank You Message Styling */
.thankyou-subscribe h1 {
  font-size: 24px; /* Larger font size for headings */
  font-weight: 600; /* Semi-bold for headings */
  margin-bottom: 10px;
}

.thankyou-subscribe p {
  font-size: 16px; /* Consistent font size for paragraphs */
  line-height: 1.5; /* Improved readability */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .loginpage {
    flex-direction: column;
    margin: 0;
    gap: 4px; /* Add spacing between the sidebars */
  }

  .login-main-sidebar {
    display: block;
    padding: 10px;
  }

  .login-right-sidebar {
    padding: 10px;
  }

  .socialMediaIcons-md {
    display: none; /* Hide the medium device social media icons */
  }

  .myLoginCard {
    border-radius: 10px;
    margin-bottom: 5px; /* Increased margin for better spacing */
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 6px,
                rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

  .subscribe-btn button {
    max-width: 100%; /* Full width on small screens */
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .loginpage {
    justify-content: center;
    flex-direction: row;
  }

  .login-right-sidebar {
    padding: 5px;
    max-width: 300px;
    min-width: 200px;
  }

  .login-main-sidebar {
    padding: 5px;
    width: calc(100% - 400px);
  }

  .socialMediaIcons-sm {
    display: none;
  }
}

@media (min-width: 992px) {
  .loginpage {
    justify-content: center;
  }

  .login-right-sidebar {
    max-width: 300px;
    min-width: 300px;
    padding: 10px;
  }

  .login-main-sidebar {
    width: calc(100% - 750px);
    padding: 10px;
    flex-wrap: wrap;
  }

  .socialMediaIcons-sm {
    display: none;
  }
}