/* Dashboard Container */
.dashboard-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: #f4f7fc;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Sidebar Menu */
.sidebar-menu {
  width: 200px;
  background-color: #2d3a3b;
  color: white;
  padding: 20px;
  height: 100vh;
  border-radius: 10px;
  overflow-y: auto;
  position: sticky;
  top: 0;
}

/* Main Content Section */
.dashboard-main-content {
  flex: 1;
  display: block; /* Ensures block layout for this section */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Dashboard Title */
.dashboard-title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
}

/* Dashboard Cards */
.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 40px;
  padding: 20px; /* Added padding to align cards within the block layout */
}

.card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.card-value {
  font-size: 24px;
  font-weight: 600;
  color: #2d3a3b;
}

/* Recent Activity */
.recent-activity {
  margin-top: 40px;
  padding: 20px; /* Added padding for better spacing */
}

.recent-activity h2 {
  margin-bottom: 20px;
}

.recent-activity ul {
  list-style-type: none;
  padding-left: 0;
}

.recent-activity li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  /* Main Content Section */
.dashboard-main-content {
  display: block; /* Ensures block layout for this section */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  .sidebar-menu {
    width: 100%;
    margin-bottom: 20px;
  }

  .dashboard-cards {
    grid-template-columns: 1fr;
  }
}

/* Sidebar Menu */
.sidebar-menu {
  width: 200px;
  background-color: #2d3a3b;
  color: white;
  padding: 20px;
  height: 100vh;
  border-radius: 10px;
  overflow-y: auto;
  position: sticky;
  top: 0;
  transition: width 0.3s ease;
}

.sidebar-menu .menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.sidebar-menu .menu-item-icon {
  font-size: 20px;
  margin-right: 10px;
}

.sidebar-menu .menu-item-text {
  font-size: 16px;
}

/* Small Device - Only Show Icons */
@media screen and (max-width: 768px) {
  .sidebar-menu {
    width: 60px;
    padding: 10px;
  }

  .sidebar-menu .menu-item {
    justify-content: center;
  }

  .sidebar-menu .menu-item-icon {
    margin-right: 0;
    font-size: 24px;
  }

  .sidebar-menu .menu-item-text {
    display: none; /* Hide the text */
  }
}
