/* General styling for the main user list container */
.user-list-div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  /* Layout styling for content */
  .user-content-div {
    display: flex;
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
    gap: 20px;
    /* margin: 20px; */
  }
  
  /* Styling for the user list table container */
  .user-list-table {
    flex: 1;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    background-color: #ffffff;
  }
  
  /* Styling for the table */
  .user-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    flex-grow: 1;
  }
  
  /* Table header and cell styling */
  .user-table th,
  .user-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
    flex-grow: 1;
  }
  
  /* Table header styling */
  .user-table th {
    background-color: #007bff; /* Primary blue color */
    color: white;
    text-transform: uppercase;
    font-size: 14px;
  }
  
  /* Table cell styling */
  .user-table td {
    font-size: 14px;
    color: #333;
  }
  
  /* Alternating row colors for better readability */
  .user-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Highlight row on hover */
  .user-table tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Error message styling */
  .error {
    color: #dc3545; /* Red for errors */
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 14px;
  }
/* Edit and Delete Button Styling */
  .actions a {
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    /* flex-direction: row; */
    /* display: flex; */
    /* flex-wrap: wrap;
    flex-grow: calc(100%); */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s ease-in-out;
  }
  
  /* Edit Button */
  .actions .edit-link {
    background-color: #007bff;
    background-image: linear-gradient(45deg, #007bff, #0056b3);
    color: white;
    flex-grow: 1;
  }
  
  .actions .edit-link:hover {
    background-color: #0056b3;
    background-image: linear-gradient(45deg, #0056b3, #004085);
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .actions .edit-link:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5);
  }
  
  /* Delete Button */
  .actions .delete-link {
    background-color: #e74c3c;
    background-image: linear-gradient(45deg, #e74c3c, #c0392b);
    color: white;
    flex-grow: 1;
  }
  
  .actions .delete-link:hover {
    background-color: #c0392b;
    background-image: linear-gradient(45deg, #c0392b, #a93226);
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .actions .delete-link:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(231, 76, 60, 0.5);
  }
  
  /* Active state for Edit and Delete buttons */
  .actions a:active {
    transform: scale(0.98);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .user-content-div {
      flex-direction: column; /* Stack sidebar and table vertically */
    }
  
    .user-table th,
    .user-table td {
      padding: 8px;
      font-size: 13px; /* Smaller font size for compact view */
    }
  
    button {
      padding: 6px 10px;
      font-size: 12px; /* Smaller button size for smaller screens */
    }
  }

  /* User Header Styling */
.users-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
}

.users-table-header h1 {
  margin: 0;
  color: #333;
  font-size: 16px;
}

/* Add Job Button Styling */
.add-user-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.add-user-btn:hover {
  background-color: #45a049;
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
  