.jobs-not-found {
    text-align: center;
    margin: 20px;
    color: #555;
  }
  .jobs-not-found h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .jobs-not-found p {
    font-size: 16px;
    color: #777;
  }
  