/* General Styles */
.landing-container hr {
  /* border: none; */
  margin: 0;
  padding: 0;
  /* border: 3px solid white; Applies border to all sides */
  border: none; /* Remove default border */
  border-top: 1px solid white; /* Style only the top border */
  background: linear-gradient(45deg, #427c91, #2a5298, #427c91, #4a69bd, #b9c8cc);

  /* border-top: 1px solid #ddd; */
  /* margin: 20px 0; */
}
.landing-container {
  min-height: 100vh; /* Ensure the container takes up the full viewport height */
  /* background: linear-gradient(135deg, #b0b1b3, #bac5d8); Gradient from dark blue to lighter blue */
  /* color: #fff; White text for better contrast */
  /* padding: 20px; Add padding for spacing */
  margin: 0;
  font-family: 'Arial', sans-serif; /* Use a clean, modern font */
}

/* .landing-container {
  font-family: Arial, sans-serif;
  margin: 0 auto;
} */

/* Hero Section */
.hero-section1 {
  background: linear-gradient(45deg, #FF6A00 20%, #6a11cb 40%, #2575fc 60%, #FF6A00 80%); 
  /* Smoother gradient */
  padding: 20px;
  border-radius: 10px;
  color: white;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Optional shadow for extra depth */
}

.hero-section2 {
  /* background: #0073e6; */
  background: linear-gradient(45deg,#FF6A00, #6a11cb,#FF6A00, #2575fc, #FF6A00);
  color: white;
  padding: 60px 20px;
  text-align: center;
  border-radius: 8px;
  margin-top: 0;
}


.hero-section {
  /* background: linear-gradient(45deg, #3498db,  #154360, #3498db); */
  /* background: linear-gradient(45deg, #1e3c72, #2a5298, #3b5998, #4a69bd, #2a5298); */
   /* Darker blue gradient */
   /* background: linear-gradient(45deg, #acae9f, #404237, #404237, #848e4c, #1c0e02); */
   background: linear-gradient(45deg, #427c91, #2a5298, #427c91, #4a69bd, #b9c8cc);


  
  color: white;
  padding: 60px 20px;
  text-align: center;
  /* border-radius: 8px; */
  margin-top: 0;
}

.hero-content h1 {
  font-size: 2.5rem;
  font-weight: 700; /* Bold text */
  margin-bottom: 20px;
  color: white; /* White text for better contrast */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Optional: Add a subtle shadow for better readability */
}

.hero-content p {
  font-size: 1.2rem;
  font-weight: 600; /* Semi-bold text */
  color: white; /* White text for better contrast */
  text-align: center;
  line-height: 1.6;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); /* Optional: Add a subtle shadow for better readability */
}
/* Search Form */
.search-form {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.search-form input,
.search-form select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 250px;
}

.search-form input:focus,
.search-form select:focus {
  outline: none;
  border-color: #0073e6;
  box-shadow: 0 0 5px rgba(0, 115, 230, 0.5);
}

/* Job Listings Section */
.job-listings-section {
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
  /* padding: 20px; */
  /* background-color: #f9f9f9; */
  border-radius: 12px;
  /* background: linear-gradient(45deg, #6a11cb, #2575fc, #FF6A00); */
  /* background: linear-gradient(135deg, #6a11cb, #2575fc); /* Gradient from purple to blue */
  /* color: #fff; White text for better contrast */
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); Subtle shadow for depth */ 
  /* background: linear-gradient(90deg, #6a11cb, #2575fc); Purple to blue gradient from left to right */
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  /* Optional shadow for extra depth */
  /* background-color: #dfebe6; */
  /* background: linear-gradient(135deg, #dfebe6, #c2d9d4); */
  background: linear-gradient(45deg, #dfebe6, #e0ece7, #e2edeb, #e4efed);

}



/* .side-panel1,
.side-panel2 {
  min-width: 150px;
  padding: 10px;
  border-radius: 8px;
} */

.jbf-side-panel1,
.jbf-side-panel2 {
  flex: 1;
}

/* Featured Jobs */
.job-featured-row {
  flex: 4;
  margin: 10px 5px 10px 5px;
  /* margin: 0 20px; */
}

.job-featured-row h2 {
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 20px;
  color: #2c3e50;
  font-weight: 600;
  /* color: #fff; White text for better contrast */
}
.job-featured-row p {
  font-size: 1rem;
  /* color: #e0e0e0; Light gray text for better readability */
  line-height: 1.6;
}
.job-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin: 20px 0;
}

.card {
  flex: 1;
  min-width: 250px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  max-height: 300px; /* Set a maximum height */
  overflow-y: auto; /* Add a scrollbar if content overflows */
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-header {
  font-size: 1.25rem;
  font-weight: bold;
  background-color: #3498db;
  color: #fff;
  padding: 15px;
  border-radius: 12px 12px 0 0;
}

.card-body {
  padding: 15px; /* Add padding for better spacing */
  word-wrap: break-word; /* Ensure long words break and wrap */
  overflow-wrap: break-word; /* Modern alternative to word-wrap */
  white-space: normal; /* Allow text to wrap */
}
.card-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 1px;
  color: #2c3e50;
  /* background-color: green; */
}

.card-text {
  flex: 1;
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
  /* background-color: yellow; */
  word-wrap: break-word; /* Ensure long words break and wrap */
  overflow-wrap: break-word; /* Modern alternative to word-wrap */
  white-space: normal; /* Allow text to wrap */
  text-align: justify;
}

/* Button */
.btn {
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  /* background-color: #3498db; */
  background: linear-gradient(45deg, #427c91, #2a5298, #427c91, #4a69bd, #b9c8cc);
 
  padding: 12px 20px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
}

.btn:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .job-listings-section{
    display: block;
    padding: 0px;
    margin: 0;
  }
  .jbf-side-panel1,.jbf-side-panel2{
    display: none;

  }
  .job-featured-row {
    padding: 0px; /* Add padding for better spacing */
  }

  .job-featured-row h2 {
    font-size: 1.8rem; /* Adjust heading size for mobile */
    text-align: center; /* Center-align the heading */
    margin-bottom: 20px; /* Add space below the heading */
  }

  .job-row {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    gap: 10px; /* Add space between cards */
  }

  .card {
    width: 100%; /* Ensure cards take full width */
    margin: 0 auto; /* Center-align cards */
    border: 1px solid #ddd; /* Add border for better visibility */
    border-radius: 8px; /* Add rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    padding: 10px; /* Add padding inside cards */
    box-sizing: border-box; /* Include padding in width calculation */
  }

  .card-header {
    font-size: 1.2rem; /* Adjust header font size */
    font-weight: bold; /* Make header bold */
    margin-bottom: 10px; /* Add space below header */
  }

  .card-title {
    font-size: 1.1rem; /* Adjust title font size */
    margin-bottom: 10px; /* Add space below title */
  }

  .card-text {
    font-size: 1rem; /* Adjust description font size */
    margin-bottom: 15px; /* Add space below description */
    color: #555; /* Use a slightly darker color for better readability */
  }

  .btn {
    width: 100%; /* Make button full width */
    padding: 15px; /* Add padding to button */
    font-size: 1rem; /* Adjust button font size */
    text-align: center; /* Center-align button text */
    /* background-color: #3498db;  */
    /* Use a primary color for the button */
    background: linear-gradient(45deg, #427c91, #2a5298, #427c91, #4a69bd, #b9c8cc);
 
    color: white; /* Use white text for better contrast */
    border: none; /* Remove default border */
    border-radius: 5px; /* Add rounded corners */
    cursor: pointer; /* Add pointer cursor for better UX */
    transition: background-color 0.3s ease; /* Add smooth hover effect */
  }

  .btn:hover {
    background-color: #2980b9; /* Darken button on hover */
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .job-listings-section{
    display: block;
    padding: 0px;
    margin: 0;
  }

  .job-featured-row {
    flex: 4;
    margin: 10px 5px 10px 5px;
    padding: 0px; /* Add padding for better spacing */
  }
}
@media (min-width: 991px) and (max-width: 1200px) {}