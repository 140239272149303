.add-scholarship-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-scholarship-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .add-scholarship-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .add-scholarship-form input,
  .add-scholarship-form select,
  .add-scholarship-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .add-scholarship-form textarea {
    resize: vertical;
    min-height: 100px; /* Adjust height as needed */
  }
  
  .add-scholarship-form .error-message {
    color: #d9534f;
    margin-bottom: 15px;
  }
  
  .add-scholarship-form .success-message {
    color: #5bc0de;
    margin-bottom: 15px;
  }
  
  .add-scholarship-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #5cb85c;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .add-scholarship-form button:hover {
    background-color: #4cae4c;
  }
  