/* Base styling for large devices (Desktops) */
.about-us {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding: 20px; */
    /* max-width: 1200px; */
    /* margin: auto; */
}

.left-side, .right-side {
    /* flex: 1;
    min-width: 150px;
    max-width: 300px; */
    padding: 10px;
}

.center-side {
    flex: 1;
    /* min-width: 600px; */
    padding: 10px;
}

.mycard {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
                rgba(0, 0, 0, 0.12) 0px -12px 30px, 
                rgba(0, 0, 0, 0.12) 0px 4px 6px, 
                rgba(0, 0, 0, 0.17) 0px 12px 13px,
                rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 20px;
    background-color: #ffffff;
}

h2 {
    font-size: 30px;
    margin-bottom: 10px;
}

p {
    font-size: 22px;
    line-height: 1.5;
    text-align: justify;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.team-member {
    flex: 1 1 calc(33.333% - 20px);
    text-align: center;
}

.team-member img {
    width: 100%;
    max-width: 150px;
    height: auto;
    border-radius: 50%;
}

.team-member h3 {
    font-size: 24px;
    margin: 10px 0;
    text-align: center;
}

.team-member p {
    font-size: 18px;
    color: #666;
    text-align: center;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.button-container a {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: white;
    background-color: #28a745;
    transition: background-color 0.3s, color 0.3s;
}

.button-container a:hover {
    background-color: #218838;
}

/* Small devices (Mobile phones) */
@media (max-width: 767.98px) {
    .about-us {
        flex-direction: column;
        padding: 10px;
    }

    .left-side, .right-side {
        display: none;
    }

    .center-side {
        /* width: 100%; */
        padding: 10px;
        align-items: center;
    }

    .mycard {
        padding: 15px;
    }

    h2 {
        font-size: 24px;
    }

    p {
        font-size: 18px;
    }

    .team-member {
        flex: 1 1 100%;
    }
}

/* Medium devices (Tablets) */
@media (min-width: 768px) and (max-width: 991px) {
    .about-us {
        flex-direction: column;
    }

    .left-side, .right-side {
        /* flex: 1; */
        min-width: 200px;
        max-width: 300px;
        /* padding: 10px; */
    }

    .center-side {
        /* width: 100%; */
        padding: 0;
    }
}

/* Large tablets and small desktops */
@media (min-width: 991px)  {
    .about-us {
        flex-direction: row;
        /* padding: 20px; */
       
    }

    .left-side, .right-side {
        /* flex: 1; */
        min-width: 200px;
        max-width: 250px;
        padding: 10px;
        /* margin: 1rem; */
    }

    .center-side {
        /* flex: 2; */
        margin-left: 1rem;
        width: calc(100% - 600px);
        /* min-width: 600px; */
        /* background-color: #218838; */
        padding: 10px;
    }
 
}

