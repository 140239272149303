/* General styling for the main user list container */
.users-container {
    display: flex;
    
  }
  .Users-page{
    flex-grow: 1;
    flex-shrink: 1;
    gap: 20px;
    margin: 20px;
  }

  /* Button styling for password visibility toggle */
.password-toggle-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    cursor: pointer;
  }

  /* User Header Styling */
.users-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
  }
  
  .users-table-header h1 {
    margin: 0;
    color: #333;
    font-size: 16px;
  }
  
  /* Add Job Button Styling */
  .add-user-btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  }
  
  .add-user-btn:hover {
    background-color: #45a049;
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }