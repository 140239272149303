/* Page container styling */
.company-container {
    display: flex;
    background-color: #f4f4f4;
  }
  
  /* Container for the company addition form */
  .add-company-container {
    flex: 1;
    max-width: 1200px;
    margin: 20px;
    padding: 30px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }

  .session-message {
    text-align: left;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 5px;
    /* text-align: center; */
    background-color: #c9dcf0;
  }
  
  .error-message {
    text-align: left;
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  .success-message {
    color: green;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
 
  
  /* Form styling */
  form {
    display: flex;
    flex-direction: column;
  }
  
  /* Label styling */
  label {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #333;
  }
  
  /* Input, select, and textarea styling */
  input, textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  /* Submit button styling */
  .submit-btn {
    padding: 12px 24px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    align-self: flex-start;
  }
  
  /* Hover effect for submit button */
  .submit-btn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  /* Optional responsiveness */
  @media (max-width: 768px) {
    .add-company-container {
      padding: 20px;
    }
  
    input, textarea {
      padding: 10px;
      font-size: 14px;
    }
  
    .submit-btn {
      padding: 10px 20px;
    }
  }
  