/* Global Reset */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* Footer Container */
.footer {
  background: linear-gradient(135deg, #1e1e1e 0%, #4a4a4a 100%);
  color: #ffffff;
  padding: 10px 0;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  width: 100%;
  font-family: 'Roboto', sans-serif;
  transition: background 0.5s ease;
}

/* Inner Footer Content */
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

/* Footer Sections */
.footer-section {
  flex: 1;
  min-width: 180px;
  padding: 10px;
  transition: transform 0.3s ease;
  align-items: center;
  
}

.footer-section:hover {
  transform: translateY(-5px);
}

.footer-section h4 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #f4f4f4;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 5px;

}

.footer-section p,
.footer-section ul {
  font-size: 14px;
  line-height: 1.8;
  color: #d3d3d3;
  text-align: center;
  padding-left: 10px;
}

.footer-section ul {
  list-style: none;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.footer-section ul li a {
  color: #ffffff;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-section ul li a:hover {
  color: #ffcc00;
  transform: translateX(5px);
  text-decoration: underline;
}

/* Footer Copyright Section */
.footer-copyright {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #bdbdbd;
  border-top: 1px solid #444444;
  padding: 10px 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .footer-section {
    padding: 5px;
    text-align: center; /* Center the content */
  }

  .footer-section ul {
    display: flex; /* Display items in a row */
    flex-direction: row; /* Align items in a row */
    gap: 10px; /* Add space between items */
    padding: 0; /* Ensure no extra padding */
    justify-content: center; /* Center items horizontally */
  }

  .footer-section ul li {
    display: inline; /* Ensure items are inline */
  }

  .footer-section ul li a {
    font-size: 14px;
    text-decoration: none; /* Remove underline from links */
    color: #ffffff; /* Ensure link color matches */
  }
}



@media (min-width: 768px) and (max-width: 920px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    min-width: 300px;
    padding: 5px;
  }

  .footer-section ul {
    gap: 10px;
  }

  .footer-section ul li a {
    font-size: 13px;
  }
}

@media (min-width: 920px) and (max-width: 1200px) {
  .footer-container {
    justify-content: space-around;
  }

  .footer-section {
    padding: 5px;
  }

  .footer-section h4 {
    font-size: 18px;
  }

  .footer-section ul {
    gap: 10px;
  }

  .footer-section ul li a {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .footer-container {
    justify-content: space-between;
    padding: 10px 0;
  }

  .footer-section {
    padding: 10px;
  }

  .footer-section h4 {
    font-size: 20px;
  }

  .footer-section ul {
    gap: 10px;
  }

  .footer-section ul li a {
    font-size: 16px;
  }
}
