/* Page container */
.add-job-page {
  display: flex;
  background-color: #f4f4f4; /* Light background color for the page */
  /* background-color: red; */
  height: fit-content;

}

/* Container for the job addition form */
.add-job-container {
  flex: 1;
  max-width: 800px; /* Increased max-width for larger screens */
  margin: 20px; /* Margin for spacing around the content */
  padding: 30px; /* Increased padding for more spacing */
  background-color: white;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: flex;
  flex-direction: column; /* Stack sections vertically */
}

.session-message {
  text-align: left;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 5px;
  /* text-align: center; */
  background-color: #c9dcf0;
}

.error-message {
  text-align: left;
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.success-message {
  color: green;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

/* Form styling */
form {
  display: flex;
  flex-direction: column; /* Stack form fields vertically */
}

/* Label styling */
label {
  font-size: 18px; /* Larger font size for better readability */
  margin-bottom: 10px; /* Space between label and input */
  font-weight: 500; /* Slightly bolder labels */
  color: #333; /* Darker color for better readability */
}

/* Input, select, and textarea styling */
input, select, textarea {
  width: 100%; /* Full width of the container */
  padding: 14px; /* Increased padding inside input fields */
  margin-bottom: 5px; /* Space between fields */
  border: 1px solid #ccc; /* Light border for inputs */
  border-radius: 6px; /* Slightly more rounded corners */
  box-sizing: border-box; /* Include padding and border in the width */
  font-size: 16px; /* Larger font size for better readability */
  resize: vertical; /* Allow textarea to resize vertically */
}

/* Submit button styling */
.submit-btn {
  padding: 14px 28px; /* Increased padding for a more prominent button */
  background-color: #007bff; /* Primary button color */
  color: #ffffff; /* Button text color */
  border: none; /* Remove default border */
  border-radius: 6px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size for the button text */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  align-self: flex-start; /* Align button to the start of the form */
}

/* Hover effect for submit button */
.submit-btn:hover {
  background-color: #0056b3; /* Darker color on hover */
  transform: scale(1.05); /* Slightly scale up the button on hover */
}

/* Optional responsiveness */
@media (max-width: 768px) {
  .add-job-container {
    padding: 20px; /* Reduce padding on smaller screens */
  }

  input, select, textarea {
    padding: 12px; /* Adjust padding inside input fields */
    margin-bottom: 5px; /* Adjust space between fields */
  }

  .submit-btn {
    padding: 12px 24px; /* Adjust button padding */
  }
}